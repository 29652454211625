import {
  defineComponent,
  onMounted,
  SetupContext,
  toRefs,
  computed,
} from 'vue';
import { useStore } from 'vuex';

const Korapay = defineComponent({
  inheritAttrs: false,
  emits: ['confirmTransfer'],
  props: {
    callback: {
      type: Function,
      required: false,
      default: (response: any) => console.log(response),
    },
    publicKey: {
      type: String,
      required: false,
    },
    amount: {
      type: [String, Number],
      required: true,
      validator: (value: string | number) => value > 0,
    },
    narration: {
      type: String,
      required: false,
    },
  },
  setup: (props, { emit }: SetupContext) => {
    const { publicKey, amount, narration } = toRefs(props);

    const { state } = useStore();

    const korapayKey = computed(() => state?.user?.keys?.korapay_public_key);
    const korapayCharge = computed(() => state?.user?.keys?.korapay_charge);

    const amountToPay = computed(
      () => Number(amount.value) + Number(korapayCharge.value),
    );

    const loadScript = (callback: any) => {
      const script: any = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://korablobstorage.blob.core.windows.net/modal-bucket/korapay-collections.min.js`;
      document.getElementsByTagName('head')[0].appendChild(script);
      if (script.readyState) {
        // IE
        script.onreadystatechange = () => {
          if (
            script.readyState === 'loaded' ||
            script.readyState === 'complete'
          ) {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else {
        // Others
        script.onload = () => {
          callback();
        };
      }
    };

    onMounted(() => {
      new Promise((resolve: any) => {
        loadScript(() => resolve());
      });
    });

    const payWithKorapay = (): void => {
      const windowSetup: any = window;
      windowSetup.Korapay.initialize({
        key: publicKey.value ?? korapayKey.value,
        amount: amountToPay.value,
        currency: 'NGN',
        customer: {
          name: `${state?.user?.first_name} ${state?.user?.last_name}`,
          email: state?.user?.email,
        },
        narration: narration.value,
        onClose: (): void => {},
        onSuccess: (data: any): void => emit('confirmTransfer', data),
        onFailed: (): void => {},
        onPending: (): void => {},
      });
    };
    return { payWithKorapay };
  },
});

export default Korapay;
